import * as fwx from "../../../../framework/forms/form-export";
import {
    KonditionEditUtils
} from "./kondition-edit-utils";

@fwx.autoinject
export class KonditionEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_konditionEditUtils: KonditionEditUtils) {
        super(element, formBaseImport);
        this.id = "kondition-edit";
        this.title = "kondition-edit.kondition-edit_caption";
        this.addModel({
            "id": "$m_Kondition",
            "webApiAction": "ERP/Kalkulation/Kondition",
            "webApiExpand": {
                'Konditionsfeld': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": [{
                "if": "variables.data.$v_idGeschaeftspartner",
                "webApiCustomKey": "IdGeschaeftspartner",
                "webApiCustomValue": "variables.data.$v_idGeschaeftspartner"
            }, {
                "if": "variables.data.$v_idArtikel",
                "webApiCustomKey": "IdArtikel",
                "webApiCustomValue": "variables.data.$v_idArtikel"
            }]
        });
        this.addVariable({
            "id": "$v_idGeschaeftspartner"
        });
        this.addVariable({
            "id": "$v_idArtikel"
        });
        this.addFunction("$f_konditionEditUtils", $f_konditionEditUtils, "functions.$f_konditionEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "konditionsfeldMitGeschaeftspartnerTyp",
            "filters": [],
            "caption": "kondition-edit.r_konditionsfeld_caption",
            "isReadOnlyExpression": "models.data.$m_Kondition && models.data.$m_Kondition.Id > 0",
            "binding": {
                "dataContext": "$m_Kondition",
                "bindTo": "IdKonditionsfeld",
                "bindToFQ": "models.data.$m_Kondition.IdKonditionsfeld"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_konditionsfeld",
            "options": {
                "optionsName": "r_konditionsfeldOptions",
                "optionsNameFQ": "r_konditionsfeldOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "filiale",
            "filters": [],
            "caption": "kondition-edit.r_filiale_caption",
            "binding": {
                "dataContext": "$m_Kondition",
                "bindTo": "IdFiliale",
                "bindToFQ": "models.data.$m_Kondition.IdFiliale"
            },
            "validationRules": [],
            "id": "r_filiale",
            "options": {
                "optionsName": "r_filialeOptions",
                "optionsNameFQ": "r_filialeOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "lager",
            "filter": ["IdFiliale", {
                "isBound": true,
                "expression": "models.data.$m_Kondition.IdFiliale"
            }],
            "filters": [],
            "caption": "kondition-edit.r_lager_caption",
            "binding": {
                "dataContext": "$m_Kondition",
                "bindTo": "IdLager",
                "bindToFQ": "models.data.$m_Kondition.IdLager"
            },
            "validationRules": [],
            "id": "r_lager",
            "options": {
                "optionsName": "r_lagerOptions",
                "optionsNameFQ": "r_lagerOptions"
            },
            "isDisabledExpression": "models.data.$m_Kondition && !models.data.$m_Kondition.IdFiliale"
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "mandantBaseObject",
            "customs": [{
                "key": "KonditionGeschaeftspartner",
                "value": "true"
            }],
            "filters": [],
            "caption": "kondition-edit.r_geschaeftspartnerinfo_caption",
            "binding": {
                "dataContext": "$m_Kondition",
                "bindTo": "IdGeschaeftspartnerInfo",
                "bindToFQ": "models.data.$m_Kondition.IdGeschaeftspartnerInfo"
            },
            "validationRules": [],
            "id": "r_geschaeftspartnerInfo",
            "options": {
                "optionsName": "r_geschaeftspartnerInfoOptions",
                "optionsNameFQ": "r_geschaeftspartnerInfoOptions"
            },
            "isDisabledExpression": "variables.data.$v_idGeschaeftspartner"
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "mandantBaseObject",
            "customs": [{
                "key": "KonditionArtikel",
                "value": "true"
            }],
            "filters": [],
            "caption": "kondition-edit.r_artikelinfo_caption",
            "binding": {
                "dataContext": "$m_Kondition",
                "bindTo": "IdArtikelInfo",
                "bindToFQ": "models.data.$m_Kondition.IdArtikelInfo"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$m_Kondition",
                    "bindTo": "$f_konditionEditUtils.artikelInfoRequiredRule",
                    "bindToFQ": "functions.$f_konditionEditUtils.artikelInfoRequiredRule"
                }
            }],
            "id": "r_artikelInfo",
            "options": {
                "optionsName": "r_artikelInfoOptions",
                "optionsNameFQ": "r_artikelInfoOptions"
            },
            "isDisabledExpression": "variables.data.$v_idArtikel"
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "format": "n2",
            "caption": "kondition-edit.r_wert_caption",
            "binding": {
                "dataContext": "$m_Kondition",
                "bindTo": "Wert",
                "bindToFQ": "models.data.$m_Kondition.Wert"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_wert",
            "options": {
                "optionsName": "r_wertOptions",
                "optionsNameFQ": "r_wertOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "waehrung",
            "filters": [{
                "webApiCustomKey": "Waehrung",
                "webApiCustomValue": "functions.$f_konditionEditUtils.currentKonditionsfeldOptions.Waehrung"
            }, {
                "webApiCustomKey": "Waehrung",
                "webApiCustomValue": "functions.$f_konditionEditUtils.currentKonditionsfeldOptions.Waehrung"
            }],
            "caption": "kondition-edit.r_waehrung_caption",
            "binding": {
                "dataContext": "$m_Kondition",
                "bindTo": "IdWaehrung",
                "bindToFQ": "models.data.$m_Kondition.IdWaehrung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_waehrung",
            "options": {
                "optionsName": "r_waehrungOptions",
                "optionsNameFQ": "r_waehrungOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 1.0,
            "format": "n0",
            "caption": "kondition-edit.r_wertper_caption",
            "binding": {
                "dataContext": "$m_Kondition",
                "bindTo": "WertPer",
                "bindToFQ": "models.data.$m_Kondition.WertPer"
            },
            "validationRules": [],
            "id": "r_wertPer",
            "options": {
                "optionsName": "r_wertPerOptions",
                "optionsNameFQ": "r_wertPerOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 1.0,
            "format": "n0",
            "caption": "kondition-edit.r_staffel_caption",
            "binding": {
                "dataContext": "$m_Kondition",
                "bindTo": "Staffel",
                "bindToFQ": "models.data.$m_Kondition.Staffel"
            },
            "validationRules": [],
            "id": "r_staffel",
            "options": {
                "optionsName": "r_staffelOptions",
                "optionsNameFQ": "r_staffelOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "kondition-edit.r_gueltigvon_caption",
            "binding": {
                "dataContext": "$m_Kondition",
                "bindTo": "GueltigVon",
                "bindToFQ": "models.data.$m_Kondition.GueltigVon"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_gueltigVon",
            "options": {
                "optionsName": "r_gueltigVonOptions",
                "optionsNameFQ": "r_gueltigVonOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "kondition-edit.r_gueltigbis_caption",
            "binding": {
                "dataContext": "$m_Kondition",
                "bindTo": "GueltigBis",
                "bindToFQ": "models.data.$m_Kondition.GueltigBis"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_gueltigBis",
            "options": {
                "optionsName": "r_gueltigBisOptions",
                "optionsNameFQ": "r_gueltigBisOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "kondition-edit.r_prioritaet_caption",
            "binding": {
                "dataContext": "$m_Kondition",
                "bindTo": "Prioritaet",
                "bindToFQ": "models.data.$m_Kondition.Prioritaet"
            },
            "validationRules": [],
            "id": "r_prioritaet",
            "options": {
                "optionsName": "r_prioritaetOptions",
                "optionsNameFQ": "r_prioritaetOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "125px",
            "caption": "kondition-edit.r_bemerkung_caption",
            "binding": {
                "dataContext": "$m_Kondition",
                "bindTo": "Bemerkung",
                "bindToFQ": "models.data.$m_Kondition.Bemerkung"
            },
            "validationRules": [],
            "id": "r_bemerkung",
            "options": {
                "optionsName": "r_bemerkungOptions",
                "optionsNameFQ": "r_bemerkungOptions"
            }
        });
        super.onConstructionFinished();
    }
}