import * as fwx from "../../../../framework/forms/form-export";
import {
    ImportEditUtils
} from "./import-edit-utils";
import {
    StatusService
} from "../../../services/status-service";

@fwx.autoinject
export class ImportEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_importEditUtils: ImportEditUtils,
        private $f_StatusService: StatusService) {
        super(element, formBaseImport);
        this.id = "import-edit";
        this.title = "import-edit.import-edit_caption";
        this.addModel({
            "id": "$m_Import",
            "webApiAction": "ERP/Import/Import",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_importEditUtils.doFreigebenCommand",
                "bindToFQ": "functions.$f_importEditUtils.doFreigebenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_importEditUtils.doCreateVorlageCommand",
                "bindToFQ": "functions.$f_importEditUtils.doCreateVorlageCommand"
            }
        });
        this.addFunction("$f_importEditUtils", $f_importEditUtils, "functions.$f_importEditUtils");
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "import-edit.r_bezeichnung_caption",
            "binding": {
                "dataContext": "$m_Import",
                "bindTo": "Bezeichnung",
                "bindToFQ": "models.data.$m_Import.Bezeichnung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 150
                    }]
                }
            }],
            "id": "r_bezeichnung",
            "options": {
                "optionsName": "r_bezeichnungOptions",
                "optionsNameFQ": "r_bezeichnungOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "status",
            "filters": [],
            "caption": "import-edit.r_status_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Import",
                "bindTo": "IdStatus",
                "bindToFQ": "models.data.$m_Import.IdStatus"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_StatusService.onValueChanged",
            "id": "r_status",
            "options": {
                "optionsName": "r_statusOptions",
                "optionsNameFQ": "r_statusOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "typen",
            "filters": [],
            "caption": "import-edit.r_fullname_caption",
            "isReadOnlyExpression": "models.data.$m_Import && models.data.$m_Import.Id > 0",
            "binding": {
                "dataContext": "$m_Import",
                "bindTo": "FullName",
                "bindToFQ": "models.data.$m_Import.FullName"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 250
                    }]
                }
            }],
            "id": "r_fullName",
            "options": {
                "optionsName": "r_fullNameOptions",
                "optionsNameFQ": "r_fullNameOptions"
            }
        });
        this.widgetCreator.addFileUploaderWithViewer(this, {
            "acceptTypeEnum": 1,
            "acceptType": ".jpg,.png,.pdf,.doc,.docx,.xls,.xlsx,.txt",
            "showClearButton": false,
            "caption": "import-edit.r_dmslink_caption",
            "binding": {
                "dataContext": "$m_Import",
                "bindTo": "DMSLink",
                "bindToFQ": "models.data.$m_Import.DMSLink"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 40
                    }]
                }
            }],
            "id": "r_dmsLink",
            "options": {
                "optionsName": "r_dmsLinkOptions",
                "optionsNameFQ": "r_dmsLinkOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "200px",
            "caption": "import-edit.r_fehlerinfo_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Import",
                "bindTo": "Fehlerinfo",
                "bindToFQ": "models.data.$m_Import.Fehlerinfo"
            },
            "validationRules": [],
            "id": "r_fehlerInfo",
            "options": {
                "optionsName": "r_fehlerInfoOptions",
                "optionsNameFQ": "r_fehlerInfoOptions"
            }
        });
        super.onConstructionFinished();
    }
}